import React, { useState, useEffect, useRef } from 'react';
import './Breathing.css';
import SideMenu from './Menu/SideMenu';

const Breathing = () => {
  const [btime, setbTime] = useState(5);
  const [oldbtime, setoldbTime] = useState(5);
  const [preBreathing, setPreBreathing] = useState(false);
  const [breathing, setBreathing] = useState(false);
  const [startBreathing, setStartBreathing] = useState(Date.now() + 500);

  const intervalRef = useRef(null);

  const wait = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  const darkenColor = (color, percentage) => {
    const [red, green, blue] = color.replace('#', '').match(/.{2}/g).map((hex) => parseInt(hex, 16));
    const darkenedRed = Math.floor(red * (1 - percentage));
    const darkenedGreen = Math.floor(green * (1 - percentage));
    const darkenedBlue = Math.floor(blue * (1 - percentage));
    return `#${darkenedRed.toString(16)}${darkenedGreen.toString(16)}${darkenedBlue.toString(16)}`;
  };

  const handleSliderChange = (value) => {
    setbTime(Math.round(value / 100));
  };

  const handleColorSelect = (color) => {
    const element = document.getElementById('ball');
    const darkColor = darkenColor(color, 0.22);
    element.style.background = `radial-gradient(circle at top left, ${color} 20%, ${darkColor} 90%)`;
  };

  useEffect(() => {
    let x = Date.now() - ((Math.ceil((Date.now() - startBreathing) / (oldbtime * 1000)) * (oldbtime * 1000)) + startBreathing);
    setTimeout(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.getElementById('ball').classList.add('notransition');
      const Time = btime;
      setoldbTime(Time);
      document.getElementById('ball').classList.remove('notransition');
      document.getElementById('ball').style.transitionDuration = `${Time}s`;
      setBreathing(!breathing);
      setPreBreathing(!preBreathing);
      setStartBreathing(Date.now());
      intervalRef.current = setInterval(() => {
        setPreBreathing((prevPreBreathing) => !prevPreBreathing);
        wait(500).then(() => {
          setBreathing((prevBreathing) => !prevBreathing);
        });
      }, Time * 1000);

      return () => {
        clearInterval(intervalRef.current);
      };
    }, -x - 100);
  }, [btime]);

  return (
    <div>
      <SideMenu onChange={handleSliderChange} onSelectColor={handleColorSelect} />
      <div className="app">
        <div className={`ball ${breathing ? 'breathe' : 'nobreath'}`} id="ball">
          <div className={`breathing-text out ${preBreathing ? 'fade-in' : 'fade-out'}`}>breathe in</div>
          <div className={`breathing-text in ${preBreathing ? 'fade-out' : 'fade-in'}`}>breathe out</div>
        </div>
      </div>
    </div>
  );
};

export default Breathing;