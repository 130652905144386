import React, { useState, useEffect } from 'react';
import './Palette.css';

const Palette = ({ colors, onSelectColor }) => {

    useEffect(() => {
        setSelectedColor("#62AAE8");
      }, []);

  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorClick = (color) => {
    setSelectedColor(color);
    if (onSelectColor) {
      onSelectColor(color);
    }
  };

  return (
    <div className="color-palette">
      {colors.map((color, index) => (
        <div
          key={index}
          className={`color-box ${selectedColor === color ? 'selected' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => handleColorClick(color)}
        />
      ))}
    </div>
  );
};

export default Palette;
