import React, { useState } from 'react';
import './Slider.css';

const SliderInput = ({ min, max, step, initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="slider-input"
      />
      <span className="slider-value">{Math.round(value / 100)}</span>
    </div>
  );
};

export default SliderInput;
