import "./Home.css";

export default function Home() {
    return(
        <div className="homepage">
            <h1>Breathing App</h1>
            <p>Made by Rainer Wang and Henry Gagnier</p>
            <h2>Strategies</h2>
            <p>Deep Breathing </p>
            <h2>Instructions</h2>
            <p>Adjust the color of the ball and the speed of breath to your liking, making sure not to exert your breathing. Proceed to follow the instructions within the ball ("breathe in", "breathe out") for 5-10 minutes. </p>
            <li>1.) Begin by sitting in a comforatable position with minimal distractions.</li>
            <li>2.) Pay attention to your breathing as you breathe in, hold, and breathe out. Make sure to visiualize the air as it goes in and out.</li>
            <li>3.) On the next breath, breathe slowly and make sure your belly and abdomen are full. Hold, and let go of the breathe. Repeat until it is natural.</li>
            <li>4.) Visualize your whole body breathing.</li>
            <h2>How This Reduces Stress</h2>
            <p>This website introduces an interactive and fun way to breathe deeply, which activates your parasympathetic nervous system, allowing you to calm down. This website also incorporates other ways to reduce stress such as the menu icons spin when you open and close the menu as a fidgeting tool.</p>
            <h2>Description</h2>
            <p>This multifunctional breathing application pro beta gamma max alpha ultra 2000 v5.7.2 titanium omega theta gold 24 carat silver bronze uranium diamond deluxe supreme special limited edition color-changing adjustable scrumdiddlymptious anti-exacerbation natural remedy organic no-pesticide, carbon-neutral, eloquent, quiet eco-friendly quintessential myriad penultimate pure immaculate splendiferous no-pulp fat-free no trans-fat added vitamin d x2 vision apple water vitamin enriched distilled Christmas orange BPA-free+ (MBAPBGMAU2000v572TOTG24CSBUDDSSLECCASAENRONPCNEQEFQMPPISNOFFNTFAVD2VAWVEDCOBPAF+) is a website designed to be user-friendly, and help lower stress levels through deep breathing. It is fully customizable to give users the best experience possible with modern UI/UX. Deep/diaphamatic breathing is a skill that helps soothing. It focuses on paying attention to a breath by breathing deeping into the belly creating a balanced flow of air.</p>
            <h2>Length</h2>
            <p>You should use this 5-10 minute every day when you are stressed. </p>
            <h2>Disclaimer</h2>
            <p>If still feeling overwhelmed, see counselor or trusted adult. </p>
            <h2>Launch App</h2>
            <br></br>
            <a href="/breathing" className="launchapp">Launch App</a>
        </div>
    )
}
