import SliderInput from "./Slider";
import Palette from "./Palette";

import React, { useState } from "react";

const SideMenu = ({onSelectColor, onChange}) => {
  const colors = ["#9c9dff", "#60c0ff", "#9cf8f7", "#9cf8dc"];


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleSliderChange = (value) => {
        onChange(value);
      };
      const handleColorSelect = (color) => {
        onSelectColor(color);
      };

  return (
    <div>
        <div className={`hamburger ${isMenuOpen ? "rotate" : ""}`} onClick={toggleMenu}><b>☰</b></div>
    <div className={`side-menu ${isMenuOpen ? "open" : ""}`}>
        <p className="settingsText">breathing time</p>
      <SliderInput min={100} max={1200} step={1} initialValue={500} onChange={handleSliderChange}/>
      <p className="settingsText">colors</p>
      <Palette colors={colors} onSelectColor={handleColorSelect}/>
    </div>
    </div>
  );
};

export default SideMenu;
